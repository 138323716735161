


























import { computed, defineComponent } from "@vue/composition-api";
import OtiumBasePage from "../OtiumBasePage.vue";
import { useUserStore } from "@/stores/userStore";

export default defineComponent({
  name: "NoOrganizations",
  components: {
    OtiumBasePage
  },
  setup() {
    const userStore = useUserStore();

    const isOrganizationAdmin = computed(() => userStore.isOrganizationAdmin);

    return {
      isOrganizationAdmin
    };
  }
});
